@import '../../../../shared/variables';
@import '../../../../shared/mixins';

.aside {
    .toggle {
        margin-top: 16px;
        border-radius: 14px;
        box-shadow: none;
        background-color: white;
    }
    .toggle-content {
        .toggle-content-area {
            @include outerForm;
            display: flex;
            flex-direction: column;
            gap: 12px;

            &__btn {
                display: flex;
                flex-direction: column;
                padding: 0;
                border-radius: 12px;
                text-align: left;
                text-transform: none;
                color: black;

                svg {
                    margin-right: 12px;
                    font-size: small;
                    color: $color-lightblack;
                }

                &-top {
                    @include width;
                    padding: 16px 20px;
                    border: 1px solid gray;
                    border-radius: 12px 12px 0 0;
        
                    p {
                        svg {
                            margin-right: 8px;
                            padding: 4px;
                            font-size: x-small;
                            border: 1px solid gray;
                            border-radius: 50%;
                            color: white;
                        }
                        font-family: $proxima-bold, sans-serif;
                        display: flex;
                        align-items: center;
                        word-break: break-word;
                    }
                }

                &-bottom {
                    @include width;
                    padding: 24px;
                    border-radius: 0 0 12px 12px;
                    border: 1px solid gray;
                    border-top: none;
                    background-color: $color-background;
    
                    p {
                        font-family: $proxima-normal, sans-serif;
                        display: flex;
                        align-items: baseline;
                        word-break: break-word;
                    }

                    @media (max-width: $break-point) {
                        padding: 12px;
                    }
                }

                &-disabled {
                    color: rgba(0, 0, 0, 0.26);

                    svg {
                        color: rgba(0, 0, 0, 0.26);
                    }

                    .toggle-content-area__btn-top {
                        border: 1px solid rgba(0, 0, 0, 0.26);

                        svg {
                            border: 1px solid rgba(0, 0, 0, 0.26);
                        }
                    }

                    .toggle-content-area__btn-bottom {
                        border: 1px solid rgba(0, 0, 0, 0.26);
                        border-top: none;
                    }
                }

                &-active {
                    outline: 2px solid $color-green;

                    .toggle-content-area__btn-top {
                        border: 1px solid transparent;
                        border-bottom: 1px solid gray;
                        background-color: color-mix(in srgb, $color-green 5%, white);

                        p {
                            svg {
                                border: 1px solid $color-green;
                                background-color: $color-green;
                            }
                        }
                    }

                    .toggle-content-area__btn-bottom {
                        border: 1px solid transparent;
                        background-color: color-mix(in srgb, $color-green 5%, $color-background);
                    }

                    &.toggle-content-area__btn-disabled {
                        outline: 2px solid color-mix(in srgb, $color-green 30%, rgba(0, 0, 0, 0.26));

                        .toggle-content-area__btn-top {
                            border-bottom: 1px solid rgba(0, 0, 0, 0.26);
                            p {
                                svg {
                                    border: 1px solid transparent;
                                    background-color: color-mix(in srgb, $color-green 30%, rgba(0, 0, 0, 0.26));
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}