@import '../variables';

.aside {
    .shared-iconButton {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
        padding: 24px;
        text-transform: capitalize;
        color: black;
        background-color: $color-background;

        p {
            font-family: $proxima-normal, sans-serif;
            padding-top: 16px;
        }
    }
}