@import '../../../../shared/variables';
@import '../../../../shared/mixins';

.imageArea {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 60vw;
    overflow: hidden;

    &__screen {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        width: 100%;
        overflow: hidden;

        img { box-shadow: 0 0 8px 4px $color-darkblue; }

        &_loading {
            max-width: 60%;

            &-progress {
                display: flex;
                justify-content: center;
            }

            &-label {
                text-align: center;
                font-family: $proxima-normal, sans-serif;
                padding-top: 24px;
            }
        }
    }

    & &__buttons {
        margin: 12px 36px 24px;

        button {
            @include btn;
            border: none;

            &:hover {
                border: none;
            }

            svg {
                font-size: small;
                padding: 6px 12px;
            }
        }

        &__minus { border-radius: 24px; }
        &__plus { border-radius: 24px; }
    }
}
