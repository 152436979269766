@font-face {
  font-family: 'Proxima Extrabold';
  src: url('./assets/fonts/Proxima_Extrabold.ttf');
}
@font-face {
  font-family: 'Proxima Bold';
  src: url('./assets/fonts/Proxima_Bold.otf');
}
@font-face {
  font-family: 'Proxima Normal';
  src: url('./assets/fonts/Proxima_Regular.otf');
}

html,
body {
  min-height: 100%;
  margin: 0;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}