@import '../variables';
@import '../mixins';

.aside {
    .info {
        @include width;
        margin-top: 32px;
        border-radius: 14px;
        box-shadow: none;
        background-color: $color-lightblue;
    }
    .info-content {
        p {
            font-family: $proxima-normal, sans-serif;
        }
    }
}