.app-header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    padding: 10px 15px;
    box-sizing: border-box;

    &__logo {
        height: 100%;
        width: auto;
    }
}
  