@import '../variables';

.aside {
    .shared-stepName {
        font-family: $proxima-extrabold, sans-serif;
    
        div {
            min-width: 32px;
            margin-right: 12px;
            background-color: black;

            span {
                font-family: $proxima-extrabold, sans-serif;
                color: white;
            }
        }
    }
}