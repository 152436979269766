@import '../variables';
@import '../mixins';

.shared-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    padding: 32px 48px;
    border-radius: 14px;
    background-color: white;

    p {
        font-family: $proxima-bold, sans-serif;
        font-size: x-large;
    }

    textarea {
        font-family: $proxima-normal, sans-serif;
        width: 100%;
        margin: 36px 0;
        padding: 0;
        border: none;
        border-radius: 4px;
        resize: none;
        font-size: large;
        outline-offset: 12px;
        outline: 1px solid black;
    }

    &__btnWrapper {
        display: flex;

        button {
            @include btn($color-red, white);
            width: auto;
            margin-left: auto;
            padding: 8px 12px;
        }
    }
}