@import '../../../../../shared/variables';
@import '../../../../../shared/mixins';

.dropzoneText {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .dropzoneText__fileName {
        font-family: $proxima-bold, sans-serif;
        padding-top: 32px;
    }

    .dropzoneText__tip {
        font-family: $proxima-normal, sans-serif;
        padding: 16px 0 24px;
    }

    button {
        @include btn;
    }
}
