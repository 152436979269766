@import '../shared/variables';
@import '../shared/mixins';

.app-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.app-content {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding-top: 60px;
}

.main-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    background-color: $color-background;

    &:first-child {
        flex-grow: 1;
    }

    .upload, .feedback-wrapper, .proof {
        @media (max-width: $break-point) {
            padding: 0 12px;
        }
    }
}

.aside {
    width: 40%;

    .MuiPaper-root {
        .MuiCardContent-root {
            @include paddingForm;
        }
    }
}

.upload, .proof {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 32px;
    gap: 16px;
}