$proxima-normal: 'Proxima Normal';
$proxima-bold: 'Proxima Bold';
$proxima-extrabold: 'Proxima Extrabold';

$font-size--basic: 16px;
$font-size--secondary: 14px;

$color-background: #f4f6f8;

$color-lightblack: #333333;

$color-green: rgb(66, 152, 146);
$color-red: rgb(227, 6, 13);

$color-lightblue: rgb(236, 238, 242);
$color-darkblue: rgb(227, 229, 235);

$color-lightred: rgb(250, 234, 233);
$color-darkred: rgb(246, 215, 213);

$color-lightgreen: rgb(245, 250, 249);
$color-darkgreen: rgb(236, 245, 244);

$break-point: 768px;
