@import '../variables';
@import '../mixins';

.aside {
    .shared-deleteForm {
        @include outerForm;
        @include paddingInnerForm;
        background-color: $color-darkblue;

        .fileInfo {
            display: flex;
            align-items: flex-start;
            padding-bottom: 24px;
            gap: 16px;

            &__fileExt {
                font-family: $proxima-bold, sans-serif;
                font-size: small;
                background-color: white;
                border-radius: 6px;
                margin: 0;
                padding: 16px 18px;
            }
            &__fileData p {
                font-family: $proxima-normal, sans-serif;
                word-break: break-all;
                margin: 0;
            }
            @media (max-width: $break-point) {
                flex-direction: column;
            }
        }

        button {
            @include btn;
        }
    }
}