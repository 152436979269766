@mixin btn($background: black, $color: white) {
    font-family: $proxima-extrabold, sans-serif;
    width: 100%;
    padding: 8px;
    background-color: $background;
    color: $color;

    &:hover {
        background-color: $background;
    }
}

@mixin width () {
    width: -webkit-fill-available;
    width: -moz-available;
}

@mixin paddingForm () {
    padding: 32px;

    @media (max-width: $break-point) {
        padding: 12px;
    }
}

@mixin paddingInnerForm () {
    padding: 24px;

    @media (max-width: $break-point) {
        padding: 12px;
    }
}

@mixin outerForm () {
    @include width;
    margin-top: 32px;
    border-radius: 14px;

    @media (max-width: $break-point) {
        margin-top: 16px;
    }
}