@import '../variables';

.shared-screenMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: $color-background;

    h2 {
        font-family: $proxima-extrabold, sans-serif;
        font-size: 2.8em;
    }
}