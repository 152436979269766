@import '../../../shared/variables';
@import '../../../shared/mixins';

.aside {
    .feedback {
        &-wrapper {
            max-height: calc(100vh - 60px);
            padding: 0 32px;
            overflow-y: auto;
        }

        &__btn {
            @include btn($color-red, white);
            margin: 72px 0;
        }
    }
}
