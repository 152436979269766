.base-Option-root, .option {
    font-family: "Proxima Normal", sans-serif;
    font-size: medium;
    width: 100%;
    margin: 8px;
    padding: 8px;
    background-color: white;
    box-shadow: 0 0 0 7px white;
    border: 1px solid lightgray;
    list-style: none;
    cursor: pointer;
}

.outer-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.inner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 675px;
    width: 250px;
    gap: 20px;
    padding: 20px 50px 10px;
    background-color: #CCC;
    border-radius: 16px;

    .title {
        font-family: "Proxima Extrabold", sans-serif;
        margin: 0;
    }

    .textfield {
        width: 100%;
        background-color: white;
        box-shadow: 0 0 0 7px white;
    }

    .paper {
        font-family: "Proxima Normal", sans-serif;
        max-height: 80px;
        overflow: auto;
        width: calc(100% - 18px);
        padding: 8px;
        box-shadow: 0 0 0 7px white;
        border-radius: 0;
        border: 1px solid lightgray;
    }

    .status {
        font-family: "Proxima Bold", sans-serif;
    }

    .select {
        font-family: "Proxima Bold", sans-serif;
        font-size: medium;
        text-align: start;
        width: 100%;
        padding: 8px;
        background-color: white;
        border: 1px solid lightgray;
        box-shadow: 0 0 0 7px white;
        cursor: pointer;
    }

    .checkbox {
        width: 100%;
        margin: 0;
        background-color: white;
        border: 1px solid lightgray;
        box-shadow: 0 0 0 7px white;

        span {
            font-family: "Proxima Bold", sans-serif;
            font-size: medium;
        }
    }

    button {
        font-family: "Proxima Extrabold", sans-serif;
    }
}
