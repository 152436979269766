@import '../variables';

.shared-alert {
    .MuiAlert-root {
        max-width: 60vw;
        margin-top: 48px;
    
        p {
            margin: 0;
        }
    }

    & .alert_success {
        background-color: $color-green;
    }

    & .alert_error {
        background-color: $color-red;
    }
}