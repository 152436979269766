@import '../../../../shared/variables';

.dropzone {
  position: relative;
  height: 100%;
  width: 100%;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    outline: none;
    cursor: pointer;
  }

  &__fade {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    opacity: 0;
    background-color: $color-green;
    pointer-events: none;
    text-align: center;
    transition: opacity 0.4s;

    svg {
        display: block;
    }
  }
}

.noDrop {
  cursor: default;
}

.visible {
    opacity: 1;
    transition: opacity 0.4s;
}

