@import '../variables';
@import '../mixins';

.aside {
    .template {
        @include paddingForm;
        @include width;
        border-radius: 14px;
        background-color: white;

        p {
            font-family: $proxima-extrabold, sans-serif;
            padding-bottom: 16px;
        }
    
        button {
            @include btn($color-red, white);
            text-decoration: none;
        }
    }
}