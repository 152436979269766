@import '../../../../../shared/variables';

.dropzoneText {
    justify-content: center;
    align-items:  center;
    text-align: center;
    
    & &__title {
        font-family: $proxima-bold, sans-serif;
        padding-top: 24px;
    }

    & &__note {
        font-family: $proxima-normal, sans-serif;
        padding-top: 12px;
    }

    & &__files {
        font-family: $proxima-normal, sans-serif;
        padding-top: 48px;
    }
}
