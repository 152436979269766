@import '../../../../shared/variables';
@import '../../../../shared/mixins';

.aside {
    .actionsList {
        @include width;
        position: relative;
        overflow: visible;
        margin-top: 16px;
        border-radius: 14px;
        box-shadow: none;
        background-color: white;
    }
    .actionsList-content {
        .actionsList-content__list {
            @include outerForm;
            display: flex;
            flex-wrap: wrap;
            gap: 4px;

            p {
                font-family: $proxima-normal, sans-serif;
            }
        }
    }
}