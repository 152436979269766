@import '../../../../shared/variables';
@import '../../../../shared/mixins';

.aside {
    .errorsList {
        @include width;
        margin-top: 16px;
        border-radius: 14px;
        box-shadow: none;
        outline: 3px solid $color-red;
        outline-offset: -3px;
        background-color: $color-lightred;

        &-success {
            outline: 2px solid $color-green;
            background-color: $color-lightgreen;

            .errorsList-content {
                &__list {
                    background-color: $color-darkgreen;

                    &__btn {
                        color: darken($color-darkgreen, 25%);
                        background-color: darken($color-darkgreen, 5%);

                        &:hover {
                            background-color: darken($color-darkgreen, 5%);
                        }
                    }
                }
            }
        }
    }
    .errorsList-content {
        &__list {
            @include outerForm;
            @include paddingInnerForm;
            background-color: $color-darkred;

            &__wrapper {
                max-height: 120px;
                overflow-y: auto;

                p {
                    font-family: $proxima-normal, sans-serif;
                    display: flex;
                    align-items: baseline;
                    word-break: break-word;
    
                    svg {
                        padding-right: 12px;
                        font-size: small;
                    }
                }  
            }

            &__btn {
                @include btn($color-red, white);
                margin-top: 24px;
                transition: none;
            }
        }
    }
}