@import '../variables';

.shared-fileName {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 320px;

    @media (max-width: $break-point) {
        max-width: 120px;
    }
}