@import '../../../shared/variables';
@import '../../../shared/mixins';

.notFound {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    background-color: $color-background;

    h2 {
        font-family: $proxima-extrabold, sans-serif;
        font-size: 3em;
        text-align: center;
    }

    button {
        @include btn($color-red, white);
        max-width: 280px;
    }
}