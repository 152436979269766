@import '../../../../shared/variables';
@import '../../../../shared/mixins';

.aside {
    .downloadForm {
        @include width;
        margin-top: 16px;
        border-radius: 14px;
        box-shadow: none;
        background-color: white;
    }
    .downloadForm-content {
        &__header {
            display: flex;
            align-items: center;
            
            a {
                margin-left: auto;
            }

            &__btn {
                @include btn($color-background, $color-lightblack);
                max-width: 144px;
                box-shadow: none;
                text-transform: capitalize
            }

            @media (max-width: $break-point) {
                display: block;

                &__btn {
                    max-width: none;
                    margin-top: 12px;
                    border-radius: 24px;
                }
            }
        }

        &__list {
            @include outerForm;

            &__btn {
                @include btn($color-red, white);
                transition: none;

                 &:last-child {
                    @include btn($color-background, $color-red);
                    margin-top: 8px;
                }
            }
        }
    }
}